.background {
  background: url("./Background.png");
  background-size: cover;
  min-height: calc(100vh - 60.5px);
}

.plane {
  stroke-dashoffset: 0;
  transition: stroke-dashoffset 4s ease-in-out .6s;
  animation: fly 5s ease-in-out infinite;
  animation-fill-mode: both;
}

.icon {
  transform: translate3d(0, 0, 0);
  fill: none;
  stroke-width: 4px;
  stroke: #f28f2f;
  stroke-linecap: square;
  stroke-dasharray: 325 325;
  z-index: 6;
}

@keyframes fly {
  0%, 50% {
    transform: translate3d(0, 0, 0) scale(1);
  }
  60% {
    transform: translate3d(-10px, 5px, 0) scale(1.05);
  }
  70% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  100% {
    transform: translate3d(150px, -150px, 0) scale(0.4);
    opacity: 0;
  }
}
